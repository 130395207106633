@import url("https://fonts.googleapis.com/css?family=Lato|Poppins|Kaushan+Script|Roboto");*/


/*
Temporary
*/




/* 
End Temporary 
*/
.booking-tile {
  background-color: #b5c6d1;
  color:#3c4172;
}

.crazy {
  color: pink;
}

html {
  box-sizing: border-box
}

a:any-link {
  color: #4BA0CA;
}

.button .is-active {
  color: #4BA0CA;
  border-color: #4BA0CA;

}

.carousel .slide .legende {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 1em;
  left: 50%;
  margin-left: -35%;
  width: 70%;
  border-radius: 0.3em;
  background-color: #ffffffe7;
  color: #1a476e;
  padding: 0.1em;
  font-size: 1.2em;
  text-align: center;
  opacity: 0.6;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out; }

  .carousel .slide .plan-legende {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: absolute;
    top: 0.4em;
    left: 50%;
    margin-left: -35%;
    width: 70%;
    border-radius: 0.3em;
    background-color: #ffffffe7;
    color: #1a476e;
    padding: 0.1em;
    font-size: 1.2em;
    text-align: center;
    opacity: 0.6;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out; }

.component {
  padding: 10px;
  background-color: #b5c6d1;
  border-radius: 1%;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3)

}
.component-title {
  color: #1a476e;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.component-card {
  min-height: 50vh ;
  background-color: #ffffff;
  border-radius: 1%;
  padding: 2vw;
  overflow: auto;
  
}

.dropdown {
  position: absolute;
  top: 0.4em;
  right:15%;
  margin-bottom: 2em;

}

.dropdown-boeking {
  position: sticky;
  width: 50%;
  margin-left: 25%;
  top: 0.4em;
  margin-bottom: 2em;

}
.dropdown-animation {
  position: absolute;
  width: 50%;
  margin-right: 25%;

}
.text-bold {
  font-weight: bold;
}

.ul .circle {
  list-style-type: circle;
  text-align: left;
  text-indent: 1em;
}

.text-normal {
  font-weight: normal;
}

.cover {
  background-image: url("./assets/pictures/cover.jpg");
  background: linear-gradient(rgba(31, 44, 108, 0.5), rgba(31, 44, 108, 0.25)),
    rgba(0, 0, 0, 0.15) url("./assets/pictures/cover.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-family: "Poppins", sans-serif;
}

.cover .title {
  font-family: "Kaushan Script", sans-serif;
  transform: rotate(-5deg);
  font-size: 5rem;
  color: whitesmoke;
}

.cover .subtitle {
  padding: 5px;
  color: whitesmoke;
}

.fill {
  max-height: 85vh;
  max-width: 95vw;
  object-fit: cover;
}

._footer {
  background-color: #fafafa;
  padding: 1rem;
}

.is-90vw {
  max-width: 90vw;
}

.portrait {
  max-height: 85vh;
  max-width: 95vw;
  object-fit: contain;
}

.has-vertically-aligned-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-map {
  background-image: url("./assets/pictures/kaart.png");
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}
.main-content {
  color: #3c4172;
  font-family: "Poppins", sans-serif;
}

.navbar-item .a {
  color: #3273dc;
}

.zot {
  color: #3273dc;
}


.overflow {
  overflow: auto;
}

.right-image img {
  border-radius: 11px;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}
.rounded-corners {
  object-fit: cover;
  border-radius: 3%;
  max-height: 90vh;

}
.section-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.125;
  letter-spacing: 2px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.section-light {
  
  padding-bottom: 5vh;
  background-color: #ffffff;
}

.section-light .section-title {
  color: #1a476e;
}

.section-dark {
 
  padding-bottom: 5vh;
  background-color: #43485c;
  color: white;
}

.section-medium {
 
  padding-bottom: 5vh;
  background-color: #b5c6d1;
  color:#3c4172;
}

.some-margin {
 margin: 2vw;
}
.text-is-larger {
  font-size: 1.2rem;
}

.smaller {
  font-size: 0.8rem;
}

.table-boek {
  font-size: 0.8rem;
}



