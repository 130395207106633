@charset "utf-8";


// check : https://bulma.io/documentation/customize/with-node-sass/#6-add-your-own-bulma-styles 

// EXAMPLE custom global variable 

  // variable ( e.g brand colors ) 
  $link-color:  #4BA0CA;

 $link: $link-color;
$navbar-item-hover-color: $link-color;
$navbar-item-color: $link-color;
$navbar-item-active-color: $link-color;

$info: $link-color;
//////////////////////////////////////////////////////







//----------------------------------
// Don't put anything below this 
@import "../node_modules/bulma/bulma.sass";